@import '/styles/base.scss';

.slide {
  min-height: 31.6rem;

  @include lg {
    min-height: 36.2rem;
  }
}

.card {
  @include focus(-0.4rem);
  width: 26.8rem;
  max-width: unset;
  height: 100%;
  box-sizing: border-box;

  @include md {
    width: 28.9rem;
  }

  @include lg {
    width: 38.4rem;
  }
}
