@import '/styles/base.scss';

.carousel {
}

.header {
}

.buttonWrapper {
  margin-top: 2.4rem;

  @include md {
    margin-top: 3.2rem;
  }
}
