@import '/styles/base.scss';

@layer {
  .root :global {
    @import 'swiper-8/css/pagination';
    @import 'swiper-8/css';
  }
}

.slide {
  max-width: fit-content;
  height: auto;
  background-color: var(--theme-copy);
}

.overlay {
  width: 100%;
  height: 100%;
}

.slideOverlay {
  height: 100%;
}

@for $i from 1 through 3 {
  .overlay-#{$i} {
    background-color: rgba(var(--theme-primary-rgb), #{1 - $i * 0.05});
  }
}

.overlay-4 {
  background-color: rgba(var(--theme-primary-rgb), 0.9);
}

.overlay-5 {
  background-color: rgba(var(--theme-primary-rgb), 0.95);
}

.overlay-4--highlight {
  background-color: rgba(var(--theme-primary-rgb), 0.8);
}

.header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.swiper {
  margin-top: 2.4rem;

  @include md {
    margin-top: 3.2rem;
  }
}

.buttons {
  display: flex;
  min-width: 5rem;
  align-items: center;
  align-self: flex-end;
  justify-content: center;
}

.button {
  @include focus;
  display: flex;
  width: 2rem;
  height: 2rem;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:first-child {
    margin-right: 1rem;
  }

  &:not(:disabled) {
    cursor: pointer;
  }
}

.buttonsHidden {
  display: none;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
}
