@import '/styles/base.scss';

.animationOverlay {
  position: fixed;
  z-index: 1000;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.lottie {
  position: fixed;
  z-index: 1001;
  bottom: 0;
  display: flex;
  width: 200vw;
  transform: translate(-33%, 0);
  transition: all 1s ease-out;

  @include lg {
    width: 100vw;
    transform: translateY(0);
    transition: all 1.3s;
  }
}
