@import '/styles/base.scss';

.textWrapper {
  @include lg {
    padding-right: 4rem;
  }
}

.link {
  display: inline-block;
  margin-top: 2.4rem;

  @include md {
    @include linkLarge;
  }
}
