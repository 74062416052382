@import '/styles/base.scss';

.card {
  @include focus;
  display: flex;
  width: 26.8rem;
  height: 25.2rem;
  box-sizing: border-box;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 2.4rem 2.4rem 4rem;
  color: var(--theme-copy);

  @include md {
    width: 28.8rem;
  }

  @include lg {
    width: 38.4rem;
    height: 27.4rem;
    padding: 4rem 4rem 4.8rem;
  }

  &:hover {
    color: var(--theme-copy);

    .link {
      color: rgba(var(--theme-copy-rgb), 0.65);
    }
  }
}

.content {
  width: 100%;
  margin-bottom: 4rem;
  word-break: break-word;

  @include md {
    margin-bottom: 4.8rem;
  }

  @include lg {
    margin-bottom: 6.4rem;
  }
}

.eyebrow {
  margin-bottom: 0.8rem;
}

.icon {
  display: inline-block;
  width: 1.4rem;
  height: 1.5rem;
  margin-left: 0.2rem;
}

.link {
  display: flex;
  align-items: flex-end;
  color: var(--theme-copy);
  transition: color 0.3s ease;

  &:hover {
    color: rgba(var(--theme-copy-rgb), 0.65);
  }
}

.logo,
.logo svg {
  max-width: 14.4rem;
  min-height: 3.6rem;
  max-height: 3.6rem;

  @include lg {
    max-width: 20rem;
    min-height: 5rem;
    max-height: 5rem;
  }
}

.logo {
  margin-bottom: 2.4rem;

  @include lg {
    margin-bottom: 4rem;
  }

  svg,
  path,
  rect {
    fill: var(--theme-copy);
  }

  img {
    width: auto !important;
    height: auto !important;
    max-height: 100%;
  }
}
