@import '/styles/base.scss';
@import '~/v1/components/navigation/navigation.constants.module.scss';

$content-padding-s: 17.7rem;
$content-padding-m: 23.6rem;
$content-padding-l: 11.11%;

.mellonLogo {
  position: absolute;
  z-index: 1;

  top: 7.8rem;
  width: 100%;
  // 6 is completely arbitrary but it makes the M fade out right above the primary nav items
  opacity: calc(1 - 6 * var(--scroll-progress, 1));
  pointer-events: none;
  transition: opacity 0.5s linear;

  svg {
    display: block;
  }

  path {
    fill: var(--theme-secondary);
    stroke: var(--theme-secondary);
  }

  @include md {
    top: 7.6rem;
  }

  @include lg {
    z-index: 300;
    top: unset;
    bottom: calc(100% - 100 * var(--vh) + 4rem);
    width: calc(#{$content-padding-l} * 3 + #{$primary-lg-width});
    margin-left: $primary-lg-width * -1;
  }
}

.mellonLogoInner {
  @include md-only {
    width: 52.8rem;
  }
}

.heroContainer {
  padding-top: $content-padding-s;
  background-color: var(--theme-primary);

  @include md {
    padding-top: $content-padding-m;
  }
  @include lg {
    padding-top: 0;
    background-color: transparent;
  }
}

.callToAction {
  @include sm-only {
    margin-bottom: 6.4rem;
  }
}

.highlightedContent {
  // Allows for the background to overlap the section spacing from the spotlight
  // CTA
  padding-top: $section-spacing-sm;
  margin-top: -$section-spacing-sm;
  background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
    var(--theme-primary);

  @include md {
    padding-top: $section-spacing-md;
    margin-top: -$section-spacing-md;
  }

  @include lg {
    padding-top: $section-spacing-lg;
    margin-top: -$section-spacing-lg;
  }
}
