@import '/styles/base.scss';

.root {
  position: fixed;
  z-index: $z-900;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100 * var(--vh));
  background-color: rgba(var(--theme-primary-rgb), 0);
  transform: translate3d(0, 0, 0);
  transition: background-color 1.6s ease;

  &.active {
    background-color: var(--theme-primary);
    transition: none;
  }
}
